/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import Helmet from "react-helmet"
 import share from "../assets/workwise/share.jpg"
 
 function SEOWorkwise({ lang, meta }) {
   const title = "Workwise"
   const metaDescription = "Le programme Workwise pour accroître le bien-être individuel et collectif, favoriser la pérennité et l'excellence des personnes et des organisations."
   const keywords = "formation, mindfulness, soft skills, stress, concentration, créativité"
   const author = "Workwise par Mind"
   const shareImage = share
 
   return (
     <Helmet
       htmlAttributes={{
         lang,
       }}
       title={title}
       titleTemplate={`Méditiation en entreprise | Workwise`}
       meta={[
         {
           name: "description",
           content: metaDescription,
         },
         {
          name: "keywords",
          content: keywords
         },
         {
           property: "og:title",
           content: title,
         },
         {
           property: "og:description",
           content: metaDescription,
         },
         {
           property: "og:type",
           content: "website",
         },
         {
           name: "twitter:card",
           content: "summary",
         },
         {
           name: "twitter:creator",
           content: author,
         },
         {
           name: "twitter:title",
           content: title,
         },
         {
           name: "twitter:description",
           content: metaDescription,
         },
         {
           name: "twitter:image",
           content: shareImage,
         },
         {
           name: "og:image",
           content: shareImage,
         },
       ].concat(meta)}
     />
   )
 }
 
 SEOWorkwise.defaultProps = {
   lang: "en",
   meta: [],
   description: "",
 }
 
 SEOWorkwise.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
 }
 
 export default SEOWorkwise
 