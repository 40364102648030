import styled, { css } from "styled-components"
import theme from "../../theme"
import { BREAKPOINT } from "../../constant";

const baseButton = css`
  border-radius: 30px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: ${theme.typography.circularBook};
  font-weight: 100;
  color: ${theme.color.workwise.background};
  border: 1px solid ${theme.color.workwise.background};
  padding: 8px 30px;
  margin-top: 20px;
  letter-spacing: 1.5px;
  display: inline-block;

  &:hover {
    color: ${theme.color.darkBlue};
    background-color: ${theme.color.workwise.background};
  }
`

export const Button = styled.button`
  ${baseButton}
`

export const ButtonHref = styled.a`
  ${baseButton}

  margin-top: 50px;
`
