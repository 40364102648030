import React from "react"
import styled from "styled-components"

import { Col, Grid, Section } from "../Grid"
import theme from "../../../theme"
import { H2, H3, TitleContainer } from "../Text"
import meditation from "../../../assets/workwise/ecologie.jpg"
import { BREAKPOINT } from "../../../constant"

const Container = styled.div`
  position: relative;
  background-color: ${theme.color.workwise.background};
  padding-bottom: 700px;

  @media (max-width: ${BREAKPOINT.TABLET}px) {
    padding-bottom: 400px;
    background-size: contain;
  }

  & ${Section} {
    position: relative;
    z-index: 2;
  }
`

const MediationGrid = styled(Grid)`
  margin-top: 80px;

  p {
    font-size: 1.25em;
    text-align: justify;
  }

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    margin-top: 0;
    margin-bottom: 200px;

    p {
      font-size: 1.15em;
      text-align: center;
    }
  }
`
const Disclaimer = styled.p`
  font-family: ${theme.typography.gascogne};
  font-size: 1.5em;
  text-align: center;
  margin-top: 100px;
  padding: 0 50px;
`

const BackgroundCut = styled.div`
  z-index: 1;
  width: 120%;
  height: 300px;
  bottom: 524px;
  position: absolute;
  transform: rotate(5deg);
  background-color: ${theme.color.workwise.background};


  @media (max-width: ${BREAKPOINT.PHONE}px) {
    bottom: 424px;
  }
`

const BackgroundImg = styled.div`
  bottom: 0;
  z-index: 0;
  width: 100%;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-image: url(${meditation});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  & p {
    font-size: 32px;
    margin-top: 300px;
    text-align: center;
    text-shadow: 0 3px 6px #04152F99;
    font-family: ${theme.typography.gascogne};
    color: ${theme.color.workwise.background};
  }

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    height: 500px;

    & p {
      margin-top: 200px;
      padding: 30px;
      font-size: 25px;
    }
  }
`


const Medidation = () => {
  return (
    <Container>
      <BackgroundCut />

      <BackgroundImg>
        <p>Accroître le bien-être individuel et collectif,<br />
        favoriser la pérennité et l'excellence des personnes<br />
        et des organisations.</p>
      </BackgroundImg>

      <Section>
        <TitleContainer>
          <H2>Développer Notre écologie intérieure</H2>
          <H3>Construire un environnement plus résilient</H3>
        </TitleContainer>

        <MediationGrid>
          <Col $margin>
            <p>
              Dans un monde incertain et mouvementé, nous avons plus
              que jamais besoin d'avoir l'esprit clair et le coeur
              ouvert pour relever les défis intérieurs, relationnels,
              sociétaux et professionnels qui s'offrent à nous.
              <br />
              <br />
              L’application de méditation Mind s’est associée aux
              plus grands experts de la mindfulness et de
              l'intelligence émotionnelle pour concevoir <strong>WORK</strong>WISE
              : un programme d’entraînement à la pleine conscience,
              fondé sur les neurosciences, l'intelligence
              émotionnelle et la pratique de la méditation, destiné
              spécifiquement aux organisations et entreprises ainsi
              qu'à leurs collaborateurs et salariés.
            </p>
          </Col>
          <Col $margin>
            <p>
              Face à des circonstances extérieures qui nous
              échappent, <strong>WORK</strong>WISE nous fait prendre conscience
              de ce que nous pouvons faire évoluer : notre attention,
              nos émotions, notre état d’esprit, nos intentions, nos
              paroles et nos actions. En d’autres termes, notre
              écologie intérieure.
              <br />
              <br />
              Prendre soin de son écologie intérieure, c’est
              construire son équilibre propre afin de contribuer à
              façonner un monde plus harmonieux et résilient. Un monde
              où les organisations humaines et leurs
              protagonistes sont épanouis, vivants, agiles et en plein
              déploiement de leur potentiel.
            </p>
          </Col>
        </MediationGrid>
      </Section>
    </Container>
  )
}

export default Medidation
