import React from "react"
import Img from "gatsby-image"
import Slider from "react-slick"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { Section, Col4, Grid } from "../Grid"
import { H2 } from "../Text"
import theme from "../../../theme"
import { FOUNDERS, BREAKPOINT } from "../../../constant"
import { Desktop, Mobile } from "../../Device"

const QUERY = graphql`
  query {
    founder1: file(relativePath: { eq: "workwise/founders/1.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    founder2: file(relativePath: { eq: "workwise/founders/2.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    founder3: file(relativePath: { eq: "workwise/founders/3.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    founder4: file(relativePath: { eq: "workwise/founders/4.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export const Person = styled.div`
  padding: 15px 15px 50px;
  max-width: 260px;
  margin: auto;
  transition: all 0.1s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  .gatsby-image-wrapper {
    border-radius: 10px;
    width: 100%;
    height: 300px;
  }

  h4 {
    font-size: 20px;
    font-family: ${theme.typography.gascogne};
    padding: 40px 0 20px;
    text-transform: lowercase;
    &:first-letter {
      text-transform: uppercase;
    }

    span {
      font-size: 22px;
      font-family: ${theme.typography.gothic};
      text-transform: uppercase;
      font-weight: bolder;
    }
  }

  p {
    font-family: ${theme.typography.circularBook};
    opacity: 0.5;
    font-size: 1em;
    white-space: pre-line;
  }
`

export const SliderContainer = styled.div`
  position: relative;
  left: -30px;
  right: -30px;
  width: calc(100% + 60px);
  margin-bottom: -70px;

  & .slick-slide {
    width: 320px;
  }

  & .slick-list {
    margin-top: 100px;
    margin-bottom: 0px;
  }
`
export const Center = styled.p`
  text-align: center;
  margin-top: 40px;
  font-size: 20px;

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    font-size: 18px;
  }
`
export const FoundersGrid = styled(Grid)`
  position: relative;
  margin: 120px -20px 0;
`

export const sliderSettings = {
  dots: false,
  autoplay: false,
  autoplaySpeed: 7000,
  centerMode: true,
  infinite: false,
  arrows: false,
  centerPadding: "55px",
}

const Founders = () => {
  const data = useStaticQuery(QUERY)

  return (
    <div>
      <Section $paddingBottom>
        <H2>L'équipe fondatrice</H2>
        <Center>
          <strong>WORK</strong>WISE a été conçu par une équipe d’experts internationaux ayant notamment construit<br />
          et enseigné des programmes de Mindfulness et intelligence émotionnelle<br />
          pour les plus grandes multinationales et certains gouvernements.
        </Center>

        <Desktop>
          <FoundersGrid>
            {FOUNDERS.map((founder, index) => (
              <Col4 key={index}>
                <Person>
                  <Img fluid={data[`founder${index + 1}`].childImageSharp.fluid} />
                  <h4>{founder.name}<br /><span>{founder.lastname}</span></h4>
                  <p dangerouslySetInnerHTML={{ __html: founder.bio }} />
                </Person>
              </Col4>
            ))}
          </FoundersGrid>
        </Desktop>

        <Mobile>
          <SliderContainer>
            <Slider {...sliderSettings}>
              {FOUNDERS.map((founder, index) => (
                <Person key={index}>
                  <Img fluid={data[`founder${index + 1}`].childImageSharp.fluid} />
                  <h4>{founder.name}<br /><span>{founder.lastname}</span></h4>
                  <p dangerouslySetInnerHTML={{ __html: founder.bio }} />
                </Person>
              ))}
            </Slider>
          </SliderContainer>
        </Mobile>
      </Section>
    </div>
  )
}

export default Founders
