import React from "react"
import Img from "gatsby-image"
import Slider from "react-slick"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { Odd, Section, Col4, Grid } from "../Grid"
import { H2, H3 } from "../Text"
import theme from "../../../theme"
import { IMPROVEMENTS, BREAKPOINT } from "../../../constant"
import { Desktop, Mobile } from "../../Device"

const QUERY = graphql`
  query {
    improvement1: file(relativePath: { eq: "workwise/improvements/1.png" }) {
      childImageSharp {
        fixed(width: 165) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    improvement2: file(relativePath: { eq: "workwise/improvements/2.png" }) {
      childImageSharp {
        fixed(width: 165) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    improvement3: file(relativePath: { eq: "workwise/improvements/3.png" }) {
      childImageSharp {
        fixed(width: 165) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    improvement4: file(relativePath: { eq: "workwise/improvements/4.png" }) {
      childImageSharp {
        fixed(width: 165) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    improvement5: file(relativePath: { eq: "workwise/improvements/5.png" }) {
      childImageSharp {
        fixed(width: 165) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    improvement6: file(relativePath: { eq: "workwise/improvements/6.png" }) {
      childImageSharp {
        fixed(width: 165) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    improvement7: file(relativePath: { eq: "workwise/improvements/7.png" }) {
      childImageSharp {
        fixed(width: 165) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    improvement8: file(relativePath: { eq: "workwise/improvements/8.png" }) {
      childImageSharp {
        fixed(width: 165) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`

const Subtitle = styled(H3)`
  margin-top: 5px;
  font-size: 26px;
  font-family: ${theme.typography.gascogne};
`
const SliderContainer = styled.div`
  position: relative;
  left: -30px;
  right: -30px;
  width: calc(100% + 60px);
  margin-bottom: -70px;

  & .slick-slide {
    width: 320px;
    margin-left: 15px;
    margin-right: 15px;

    &:first-child {
      margin-left: 0;
    }
  }

  & .slick-list {
    margin-top: 100px;
    margin-bottom: 100px;
  }
`

const Center = styled.p`
  font-size: 20px;
  margin: 0 auto;
  margin-top: 60px;
  max-width: 500px;
  text-align: center;
`
const ImprovementsGrid = styled(Grid)`
  margin: 60px -20px 0;
  margin-top: 100px;
`
const ColImprovement = styled(Col4)`
  margin: 20px 0;
`
const Improvement = styled.div`
  background-color: ${theme.color.workwise.background};
  border-radius: 10px;
  padding: 15px 15px 20px;
  text-align: center;
  margin: 0 20px;
  height: 100%;

  .gatsby-image-wrapper {
    margin: 30px 0;
  }

  p {
    color: ${theme.color.darkBlue};
    font-family: ${theme.typography.gascogne};
    font-size: 1em;
    padding-left: 14px;
    padding-right: 14px;
  }

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    margin: 0;
    display: block;
    width: 320px;
  }
`

const settings = {
  dots: false,
  autoplay: false,
  autoplaySpeed: 7000,
  centerMode: true,
  infinite: false,
  arrows: false,
  centerPadding: "40px",
}

const Improvements = () => {
  const data = useStaticQuery(QUERY)

  return (
    <Odd>
      <Section $paddingBottom>
        <H2>Les Bénéfices</H2>
        <Subtitle>Des résultats prouvés</Subtitle>
        <Center>
          De nombreuses études scientifiques ont démontré les bienfaits de la
          pleine conscience. <br />
          Les outils, pratiques, ressources et exercices transmis dans le
          programme <strong>WORK</strong>WISE, vous aideront à :
        </Center>

        <Desktop>
          <ImprovementsGrid>
            {IMPROVEMENTS.map((improvement, index) => (
              <ColImprovement key={index}>
                <Improvement>
                  <Img
                    fixed={data[`improvement${index + 1}`].childImageSharp.fixed}
                  />
                  <p>{improvement}</p>
                </Improvement>
              </ColImprovement>
            ))}
          </ImprovementsGrid>
        </Desktop>

        <Mobile>
          <SliderContainer>
            <Slider {...settings}>
              {IMPROVEMENTS.map((improvement, index) => (
                <Improvement key={index}>
                  <Img
                    fixed={data[`improvement${index + 1}`].childImageSharp.fixed}
                  />
                  <p>{improvement}</p>
                </Improvement>
              ))}
            </Slider>
          </SliderContainer>
        </Mobile>

      </Section>
    </Odd>
  )
}

export default Improvements
