import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "react-slick"

import theme from "../../../theme"
import { BREAKPOINT } from "../../../constant"
import { Desktop, Mobile } from "../../Device"
import partners1 from "../../../assets/workwise/partners/1.png";
import partners2 from "../../../assets/workwise/partners/2.png";
import partners3 from "../../../assets/workwise/partners/3.png";
import partners4 from "../../../assets/workwise/partners/4.png";
import partners5 from "../../../assets/workwise/partners/5.png";


const SLIDER_SETTINGS = {
  autoplay: true,
  slidesToShow: 2,
}

/*
const NB_PARTNERS = 5
const QUERY = graphql`
  query {
    partner1: file(relativePath: { eq: "workwise/partners/1.png" }) {
      childImageSharp {
        fluid(maxHeight: 80) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    partner2: file(relativePath: { eq: "workwise/partners/2.png" }) {
      childImageSharp {
        fluid(maxHeight: 50) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    partner3: file(relativePath: { eq: "workwise/partners/3.png" }) {
      childImageSharp {
        fluid(maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    partner4: file(relativePath: { eq: "workwise/partners/4.png" }) {
      childImageSharp {
        fluid(maxHeight: 30) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    partner5: file(relativePath: { eq: "workwise/partners/5.png" }) {
      childImageSharp {
        fluid(maxHeight: 60) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
*/

const Container = styled.div`
  background-color: ${theme.color.white};
  padding: 20px 0;
`
const Panel = styled.div`
  padding: 0 15px;
  max-width: 100%;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;

    @media (max-width: ${BREAKPOINT.PHONE}px) {
      height: 120px;
    }
  }
`

const PanelContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
`
const PartnerImg = styled.img`
  max-width: 100%;
  max-height: 100%;

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    height: auto !important;
    width: 100%;
  }
`

const partnersImg = [
  { img: partners1, maxHeight: 80 },
  { img: partners2, maxHeight: 50 },
  { img: partners3, maxHeight: 100 },
  { img: partners4, maxHeight: 30 },
  { img: partners5, maxHeight: 60 }
];

const Partners = () => {
  // const data = useStaticQuery(QUERY)
  const [settings, setSettings] = useState(SLIDER_SETTINGS)

  /*
  useEffect(() => {
    setSliderOptions()
  }, [])

  const setSliderOptions = () => {
    const centerPadding = window.innerWidth < BREAKPOINT.TABLET ? "0px" : "30%"

    setSettings({
      ...SLIDER_SETTINGS,
      // centerPadding,
    });
  }
  */

  const partners = partnersImg.map((partnerImg) => (
    <Panel key={partnerImg}>
      <div>
        <PartnerImg src={partnerImg.img} style={{ height: partnerImg.maxHeight }} />
      </div>
    </Panel>
  ))

  return (
    <Container>
      <Mobile>
        <Slider {...settings}>{partners}</Slider>
      </Mobile>

      <Desktop>
        <PanelContainer>{partners}</PanelContainer>
      </Desktop>
    </Container>
  )
}

export default Partners
