import React from "react"
import Img from "gatsby-image"
import Slider from "react-slick"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { Section, Col4, Grid } from "../Grid"
import { H2 } from "../Text"
import { Person, Center, FoundersGrid, SliderContainer, sliderSettings } from "./Founders"
import { INSTRUCTORS } from "../../../constant"
import { Desktop, Mobile } from "../../Device"

const QUERY = graphql`
  query {
    instructor1: file(relativePath: { eq: "workwise/instructors/1.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instructor2: file(relativePath: { eq: "workwise/instructors/2.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instructor3: file(relativePath: { eq: "workwise/instructors/3.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instructor4: file(relativePath: { eq: "workwise/instructors/4.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instructor5: file(relativePath: { eq: "workwise/instructors/5.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instructor6: file(relativePath: { eq: "workwise/instructors/6.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instructor7: file(relativePath: { eq: "workwise/instructors/7.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instructor8: file(relativePath: { eq: "workwise/instructors/8.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instructor9: file(relativePath: { eq: "workwise/instructors/9.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instructor10: file(relativePath: { eq: "workwise/instructors/10.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instructor11: file(relativePath: { eq: "workwise/instructors/11.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instructor12: file(relativePath: { eq: "workwise/instructors/12.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instructor13: file(relativePath: { eq: "workwise/instructors/13.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    instructor14: file(relativePath: { eq: "workwise/instructors/14.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    filler: file(relativePath: { eq: "workwise/instructors/filler.png" }) {
      childImageSharp {
        fluid(maxWidth: 340) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const Filler = styled.div`
  flex: 1 0 46%;
  position: relative;
`

const ImgContainer = styled.div`
  position: absolute;
  max-width: 340px;
  width: 100%;
  right: 24%;
  top: 24%;
`


const Location = styled.p`
  margin-top: 15px;
  letter-spacing: 1px;
  opacity: 0.3 !important;
  text-transform: uppercase;
  font-size: 10px !important;
`

const Instructors = () => {
  const data = useStaticQuery(QUERY)

  return (
    <div>
      <Section $paddingBottom>
        <H2>Les formateurs</H2>
        <Center>
          Les 4 modules de formation sont transmis en présentiel<br />
          ou on-line par une équipe de formateurs expérimentés,<br />
          formés au programme <strong>WORK</strong>WISE.
        </Center>

        <Desktop>
          <FoundersGrid>
            {INSTRUCTORS.map((founder, index) => (
              <Col4 key={index}>
                <Person>
                  <Img
                    fluid={data[`instructor${index + 1}`].childImageSharp.fluid}
                  />
                  <h4>
                    {founder.name}
                    {founder.inline ? " " : <br />}
                    <span>{founder.lastname}</span>
                  </h4>
                  <p dangerouslySetInnerHTML={{ __html: founder.bio }} />
                  <Location>{founder.location}</Location>
                </Person>
              </Col4>
            ))}

            <Filler>
              <ImgContainer>
                <Img fluid={data.filler.childImageSharp.fluid} />
              </ImgContainer>
            </Filler>
          </FoundersGrid>
        </Desktop>

        <Mobile>
          <SliderContainer>
            <Slider {...sliderSettings}>
              {INSTRUCTORS.map((founder, index) => (
                <Person key={index}>
                  <Img fluid={data[`instructor${index + 1}`].childImageSharp.fluid} />
                  <h4>
                    {founder.name}
                    {founder.inline ? " " : <br />}
                    <span>{founder.lastname}</span>
                  </h4>
                  <p dangerouslySetInnerHTML={{ __html: founder.bio }} />
                  <Location>{founder.location}</Location>
                </Person>
              ))}
            </Slider>
          </SliderContainer>
        </Mobile>

      </Section>
    </div>
  )
}

export default Instructors
