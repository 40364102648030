import styled from "styled-components"
import theme from "../../theme"
import { BREAKPOINT } from "../../constant"


export const H1 = styled.h1`
  span {
    font-size: 3.125em;
    line-height: 1.2em;
    font-family: ${theme.typography.gothic};
    text-transform: uppercase;
    display: block;

    @media (max-width: ${BREAKPOINT.PHONE}px) {
      font-size: 2.2em;
    }
  }

  span:first-child,
  span:last-child {
    font-size: 1.875em;
    text-transform: none;
    font-family: ${theme.typography.gascogne};

    @media (max-width: ${BREAKPOINT.PHONE}px) {
      font-size: 1.5em;
    }
  }
`

export const H2 = styled.h2`
  text-transform: uppercase;
  font-size: 2.75em;
  font-family: ${theme.typography.gothic};
  line-height: 1.1em;
  text-align: center;

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    font-size: 1.6em;
  }
`

export const H3 = styled.h3`
  font-size: 2em;
  font-family: ${theme.typography.gascogne};
  text-align: center;
  margin: 20px 0 20px;

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    font-size: 1.5em;
  }
`

export const TitleContainer = styled.div`
  margin: 0 auto;
  max-width: 560px;

  & ${H3} {
    padding-left: 50px;
    padding-right: 50px;

    @media (max-width: ${BREAKPOINT.PHONE}px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
`