import React, { useEffect, useRef, useState } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import Slider from "react-slick"
import { graphql, useStaticQuery } from "gatsby"

import { Col, Grid, Section } from "../Grid"
import { H2, H3 } from "../Text"
import CurveText from "../CurveText"
import theme from "../../../theme"
import { BREAKPOINT, CARDS_PROGRAM } from "../../../constant"
import Left from "../../../assets/icons/left.svg"
import Right from "../../../assets/icons/right.svg"
import { Desktop, Mobile } from "../../Device"

const QUERY = graphql`
  query {
    creativity: file(relativePath: { eq: "workwise/visuel-creatif.png" }) {
      childImageSharp {
        fixed(width: 250) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    learning: file(relativePath: { eq: "workwise/elearning.png" }) {
      childImageSharp {
        fixed(width: 220) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    training: file(relativePath: { eq: "workwise/training.png" }) {
      childImageSharp {
        fixed(width: 220) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`
const Text = styled.p`
  padding: 40px 0;
  padding-top: 80px;
  font-size: 20px;
  text-align: justify;

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    font-size: 18px;
    text-align: center;
  }
`
const PanelContainer = styled.div`
  padding: 0 15px;
  height: 100%;
  outline: none;

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    padding: 0 5px;
  }
`
const Panel = styled.div`
  border-radius: 10px;
  padding: 60px 50px 90px;
  min-height: 400px;
  height: 100%;
  background-color: ${theme.color.white};
  position: relative;

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    padding: 60px 25px 90px;
  }

  h3 {
    text-transform: uppercase;
    font-size: 0.8em;
  }

  h4 {
    font-family: ${theme.typography.gothic};
    font-size: ${theme.typography.text}px;
    text-transform: uppercase;
    margin-bottom: 25px;
    margin-top: 40px;
  }

  p {
    font-family: ${theme.typography.circularBook};
    font-size: ${theme.typography.text}px;
  }
`
const ModuleTitle = styled.h4`
  ${({ $color }) => ($color ? `color: ${$color};` : ``)};
`
export const DotsContainer = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: -20px;
`
export const Dots = styled.ul`
  & > li {
    margin: 0;
  }
`
const SliderContainer = styled.div`
  position: relative;

  & .slick-slide {
    margin-left: 15px;
    margin-right: 15px;
  }

  & .slick-list {
    margin-top: 100px;
    margin-bottom: 100px;
  }
`
const Next = styled.button`
  position: absolute;
  right: 20px;
  bottom: 50%;
  font-family: ${theme.typography.gothic};
  font-size: ${theme.typography.default}px;

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    display: none;
  }
`
const Prev = styled.button`
  position: absolute;
  left: 20px;
  bottom: 50%;
  font-family: ${theme.typography.gothic};
  font-size: ${theme.typography.default}px;

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    display: none;
  }
`
const ColImg = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .gatsby-image-wrapper {
    margin: 20px 0;
  }
`
const IllusContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-family: ${theme.typography.gothic};
  font-size: 99px;
  margin-bottom: -30px;

  @media (max-width: ${BREAKPOINT.TABLET}px) {
    margin-top: 20px;
    width: 100%;
    margin-bottom: 0;
  }
`

const CurveTraining = styled(CurveText)`
  position: relative;
  margin: auto;
  bottom: -95px;
  transform: rotate(27deg);
  left: 30px;
`
const CurveLearning = styled(CurveText)`
  position: relative;
  margin: auto;
  bottom: -90px;
  transform: rotate(17deg);
  left: 20px;
`
const TrainingTitle = styled(H3)`
  font-size: 32px;
  margin-top: 70px;
  padding-top: 30px;
  font-family: ${theme.typography.gascogne};

  @media (max-width: ${BREAKPOINT.TABLET}px) {
    margin-top: 20px;
    margin-bottom: 0;
  }
`
const TrainingGrid = styled(Grid)`
  margin-top: 100px;
`
const Caption = styled.p`
  text-transform: uppercase;
  margin: 30px 0 15px;

  @media (max-width: ${BREAKPOINT.TABLET}px) {
    margin-top: 10px;
  }
`
const Cartel = styled.p`
  max-width: 350px;
  text-align: center;
  margin: auto;
  opacity: 0.7;

  @media (max-width: ${BREAKPOINT.TABLET}px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`
const CardContainer = styled(Section)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 70px 20px;
  margin-bottom: 180px;
`
const Card = styled.div`
  width: 320px;
  border-radius: 10px;
`
const CardHeader = styled.div`
  display: flex;
  padding: 0 10px;
  border-radius: 10px 10px 0 0;
  background-color: ${props => props.color};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 140px;
`
const CardTitle = styled.h3`
  color: white;
  font-size: 18px;
  text-align: center;
  text-transform: uppercase;
  font-family: ${theme.typography.gothic};
`
const CardCaption = styled.h4`
  color: white;
  opacity: 0.6;
  font-size: 12px;
  text-transform: uppercase;
`
const CardText = styled.div`
  color: ${theme.color.workwise.darkBlue};
  background: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify: center;
  min-height: 230px;
  padding: 0 32px;
  text-align: center;
  border-radius: 0 0 10px 10px;
`
const WhiteContainer = styled.div`
  background: ${theme.color.workwise.background};
  color: ${theme.color.workwise.darkBlue};
`
const Container = styled.div`
  width: 100%;
  background: ${theme.color.workwise.darkBlue};
  color: ${theme.color.workwise.background};
`
const SLIDER_SETTINGS = {
  dots: true,
  autoplay: false,
  autoplaySpeed: 7000,
  centerMode: true,
  infinite: false,
  centerPadding: `80px`,
  appendDots: dots => (
    <DotsContainer>
      <Dots>{dots}</Dots>
    </DotsContainer>
  ),
}

const Program = () => {
  const [settings, setSettings] = useState(SLIDER_SETTINGS)
  const { training, learning, creativity } = useStaticQuery(QUERY)
  const slider = useRef(null)

  const handleNext = () => {
    if (slider) {
      slider.current.slickNext()
    }
  }

  const handlePrev = () => {
    if (slider) {
      slider.current.slickPrev()
    }
  }

  useEffect(() => {
    setSettings({
      ...SLIDER_SETTINGS,
      centerPadding: `${Math.min(80, window.innerWidth * 0.07)}px`,
    })
  }, [])

  return (
    <Container>
      <Section>
        <Grid>
          <Col>
            <H2>Le déroulé</H2>
            <Text>
              <strong>WORK</strong>WISE s’articule en 8 thématiques correspondant
              aux enjeux actuels des organisations. Chaque
              thématique fait l’objet de 1h45 de formation en live et 3
              sessions de e-learning sur l’application dédiée. <br />
              <br />
              Le programme démarre en posant les fondations de la
              pleine conscience, puis aborde différents aspects de
              l’intelligence émotionnelle afin d’explorer sa relation à
              soi, aux autres et au monde.
            </Text>
          </Col>
          <ColImg $center>
            <Img fixed={creativity.childImageSharp.fixed} />
          </ColImg>
        </Grid>
      </Section>

      <Desktop>
        <CardContainer>
          {CARDS_PROGRAM.map((card) => (
            <Card key={`card-${card.color}`}>
              <CardHeader color={card.color}>
                <CardTitle>{card.title}</CardTitle>
                <CardCaption>{card.caption}</CardCaption>
              </CardHeader>
              <CardText><p>{card.text}</p></CardText>
            </Card>
          ))}
        </CardContainer>
      </Desktop>

      <Mobile>
        <SliderContainer>
          <Slider {...settings} ref={slider}>
            {CARDS_PROGRAM.map((card) => (
              <Card key={`card-${card.color}`}>
                <CardHeader color={card.color}>
                  <CardTitle>{card.title}</CardTitle>
                  <CardCaption>{card.caption}</CardCaption>
                </CardHeader>
                <CardText><p>{card.text}</p></CardText>
              </Card>
            ))}
          </Slider>
        </SliderContainer>
      </Mobile>

      {/*
      <SliderContainer>
        <Slider {...settings} ref={slider}>
          <PanelContainer>
            <Panel>
              <h3>Module 1</h3>
              <Grid>
                <Col $minWidth={250}>
                  <ModuleTitle $color={theme.color.workwise.red}>
                    ENTRAINER SON ATTENTION
                  </ModuleTitle>
                  <p>
                    La pleine conscience est une qualité de présence attentive
                    et bienveillante. Elle s’entraine par la méditation et ses
                    bienfaits sont prouvés par la science.
                  </p>
                </Col>
                <Col $minWidth={250}>
                  <ModuleTitle $color={theme.color.workwise.blue}>
                    Cultiver de bonnes Habitudes mentales
                  </ModuleTitle>
                  <p>
                    Nous héritons d’un esprit humain conçu pour se focaliser sur
                    le négatif afin d’assurer sa survie. Comment orienter notre
                    attention pour développer une posture ouverte et confiante ?
                  </p>
                </Col>
              </Grid>
            </Panel>
          </PanelContainer>
          <PanelContainer>
            <Panel>
              <h3>Module 2</h3>
              <Grid>
                <Col $minWidth={250}>
                  <ModuleTitle $color={theme.color.workwise.orange}>
                    Comprendre et réguler ses émotions
                  </ModuleTitle>
                  <p>
                    Nos émotions sont des informations que nous avons rarement
                    appris à décrypter. Comment comprendre et réguler nos états
                    d’âme pour agir avec discernement ?
                  </p>
                </Col>
                <Col $minWidth={250}>
                  <ModuleTitle $color={theme.color.workwise.green}>
                    Apprendre à prendre soin de soi
                  </ModuleTitle>
                  <p>
                    Certaines de nos réactions automatiques minent nos
                    ressources intérieures et nos talents. Comment changer la
                    manière dont on se traite pour mieux écouter ses limites et
                    le regard que l’on porte sur soi pour construire plus de
                    confiance ?
                  </p>
                </Col>
              </Grid>
            </Panel>
          </PanelContainer>
          <PanelContainer>
            <Panel>
              <h3>Module 3</h3>
              <Grid>
                <Col $minWidth={250}>
                  <ModuleTitle $color={theme.color.workwise.yellow}>
                    Développer la bienveillance et une meilleure communication
                  </ModuleTitle>
                  <p>
                    La qualité de nos relations, indispensable à notre
                    épanouissement et notre succès, est parfois compromise par
                    l’anxiété et la pression. Comment cultiver bienveillance et
                    empathie pour mieux se comprendre ?
                  </p>
                </Col>
                <Col $minWidth={250}>
                  <ModuleTitle $color={theme.color.workwise.purple}>
                    Traverser difficultés et conflits dans la confiance
                  </ModuleTitle>
                  <p>
                    Certaines situations nous font réagir au quart de tour avec
                    des conséquences regrettables. Comment créer un espace de
                    recul pour choisir consciemment sa réponse avec flexibilité
                    et ouverture ?
                  </p>
                </Col>
              </Grid>
            </Panel>
          </PanelContainer>
          <PanelContainer>
            <Panel>
              <h3>Module 4</h3>
              <Grid>
                <Col $minWidth={250}>
                  <ModuleTitle $color={theme.color.workwise.salmon}>
                    Aligner ses actions avec ses intentions
                  </ModuleTitle>
                  <p>
                    La frénésie du quotidien et l’agitation de notre esprit
                    peuvent nous embarquer dans tous les sens. Comment clarifier
                    nos intentions profondes afin d’aligner nos paroles et nos
                    actions avec nos aspirations ?
                  </p>
                </Col>
                <Col $minWidth={250}>
                  <ModuleTitle>
                    S'engager au service d'un projet qui a du sens
                  </ModuleTitle>
                  <p>
                    Après les semaines à explorer son fonctionnement pour
                    construire équilibre intérieur et confiance, comment
                    s’engager pour donner le meilleur de soi-même au service de
                    tous ?
                  </p>
                </Col>
              </Grid>
            </Panel>
          </PanelContainer>
        </Slider>
        <Prev onClick={handlePrev}>
          <Left />
        </Prev>
        <Next onClick={handleNext}>
          <Right />
        </Next>
      </SliderContainer>
      */}

      <WhiteContainer>
        <Section $paddingBottom>
          <H2>Le format</H2>
          <TrainingGrid>
            <Col $center>
              <Img fixed={training.childImageSharp.fixed} />
              <TrainingTitle>Formations Live</TrainingTitle>
              <Caption>14 Heures</Caption>
              <Cartel>
                8 modules de 1h45 avec un formateur ou une formatrice,
                en présentiel ou online, alternant théorie, pratiques
                de méditation et échanges entre participants.
              </Cartel>
            </Col>
            <IllusContainer>+</IllusContainer>
            <Col $center>
              <Img fixed={learning.childImageSharp.fixed} />
              <TrainingTitle>E - Learning</TrainingTitle>
              <Caption>24 séances</Caption>
              <Cartel>
                24 séances (d'une 20aine de minutes), exercices, enseignements et témoignages à suivre
                quotidiennement via une application mobile dédiée.
              </Cartel>
            </Col>
          </TrainingGrid>
        </Section>
      </WhiteContainer>
    </Container>
  )
}

export default Program
