import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { graphql } from "gatsby"

import { Grid, Odd, Section } from "../components/workwise/Grid"
import OnePageLayout from "../layouts/OnePage"
import SEOWorkwise from "../components/seo-workwise"
import { H1, H2 } from "../components/workwise/Text"
import { ButtonHref } from "../components/workwise/Button"
import { BREAKPOINT, WORKWISE_CONTACT_LINK, IOS_WORKWISE_LINK, ANDROID_WORKWISE_LINK } from "../constant"
import ImprovementsSection from "../components/workwise/sections/Improvements"
import MedidationSection from "../components/workwise/sections/Meditation"
import ProgramSection from "../components/workwise/sections/Program"
import FoundersSection from "../components/workwise/sections/Founders"
import PartnersSection from "../components/workwise/sections/Partners"
import QuoteSection from "../components/workwise/sections/Quote"
import ExpertsSection from "../components/workwise/sections/Experts"
import InstructorsSection from "../components/workwise/sections/Instructors"
import AndroidSrc from '../assets/google-play-fr.png'
import IosSrc from '../assets/Download_on_the_App_Store_Badge_FR_RGB_blk_100517.png'
import theme from "../theme"
import share from "../assets/share-workwise.png"
import { Desktop } from "../components/Device"

const Top = styled(Odd)`
  overflow: hidden;
`
const TopGrid = styled(Grid)`
  margin-top: 100px;
`
const Title = styled.div`
  flex: 1;
  min-width: 510px;

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    z-index: 1;
    position: relative;
    padding: 0 25px;
    margin-bottom: -200px;
  }
`
const ImgContainer = styled.div`
  flex: 1;
  position: relative;
  text-align: right;
  min-width: 400px;
  right: -100px;
  bottom: -10px;

  .gatsby-image-wrapper {
    width: 100%;
    max-width: 500px;
    display: inline-block;
  }

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    z-index: 0;
    right: -50px;
    bottom: -30px;
    position: relative;

    .gatsby-image-wrapper {
      max-width: 400px;
    }
  }
`
const Footer = styled.footer`
  position: relative;
  padding-bottom: 160px;
  text-align: center;
`
const Contact = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;

  h2 {
    text-transform: uppercase;
    margin-right: 10px;
    font-size: ${theme.typography.default}px;
  }
`
const Mailto = styled.a`
  font-family: ${theme.typography.gascogne};
  font-size: 25px;
  margin-top: 20px;
  display: block;
`
const Center = styled.div`
  text-align: center;
  margin-top: 80px;
  font-size: ${theme.typography.bookSize}px;
`
const Orange = styled.div`
  text-align: center;
  color: ${theme.color.workwise.orange};
  font-size: ${theme.typography.bookSize}px;
  text-transform: uppercase;
  margin-bottom: 30px;
`

const CardContainer = styled(Section)`
  display: grid;
  grid-gap: 25px;
  max-width: 1000px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    display: block;
  }
`

const Card = styled.div`
  background: white;
  padding: 35px;
  border-radius: 20px;
  max-width: 400px;
  box-shadow: 0 3px 6px #00000029;

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    &:last-child {
      margin-top: 30px;
    }
  }
`

const FooterRotateImage = styled.div`
  background: ${theme.color.workwise.background};
  width: 120%;
  top: -62px;
  height: 120px;
  position: absolute;
  transform: rotate(-3deg);
`

const FirstSection = styled(Section)`
  padding-top: 70px;
`

const DownloadsContainer = styled.div`
  margin-top: 86px;

  text-align: center;
  font-weight: bold;

  & > p {
    font-family: ${theme.typography.circular};
    color: ${theme.color.workwise.darkBlue};
    font-size: ${theme.typography.bookSize}px;
  }
`

const DownloadsContent = styled.div`
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > *:first-child {
    margin-right: 24px;
  }

  @media (max-width: ${theme.breakpoint}px) {
    & > *:first-child {
      margin-right: 15px;
    }
  }
`

const DownloadImg = styled.a`
  width: 214px;
  display: block;
  cursor: pointer;

  & > * {
    width: 100%;
  }

  @media (max-width: ${theme.breakpoint}px) {
    width: 50%;
    max-width: 170px;
  }
`

const Workwise = ({ data }) => {
  const { logo, logodark, flowerbg, footer } = data

  return (
    <OnePageLayout>
      <SEOWorkwise />
      <Top>
        <FirstSection>
          <Img
            fluid={logo.childImageSharp.fluid}
            style={{ margin: "auto", width: "100%", maxWidth: 250 }}
          />
          <TopGrid>
            <Title>
              <H1>
                <span>Les plus</span>
                <span>
                  Beaux projets
                  <br /> se réalisent
                </span>
                <span>dans des esprits apaisés</span>
              </H1>
              <ButtonHref href={`mailto:${WORKWISE_CONTACT_LINK}`}>
                Nous contacter
              </ButtonHref>
            </Title>
            <ImgContainer>
              <Img fluid={flowerbg.childImageSharp.fluid} />
            </ImgContainer>
          </TopGrid>
        </FirstSection>
      </Top>

      <MedidationSection />
      <ProgramSection />
      <ImprovementsSection />
      <FoundersSection />
      <ExpertsSection />
      <InstructorsSection />
      <PartnersSection />

      <QuoteSection />

      <Footer>
        <FooterRotateImage />
        <CardContainer>
          <Card>
            <Img fixed={logodark.childImageSharp.fixed} />
            <Mailto href={`mailto:${WORKWISE_CONTACT_LINK}`}>{WORKWISE_CONTACT_LINK}</Mailto>
          </Card>
          <Card>
            <a href="https://www.mind-app.io">
              <Img fixed={footer.childImageSharp.fixed} />
            </a>
            <Mailto>Un programme Mind</Mailto>
          </Card>
        </CardContainer>

        <DownloadsContainer>
          <p>Télécharger l'application</p>

          <DownloadsContent>
            <DownloadImg target="_blank" href={IOS_WORKWISE_LINK}><img src={IosSrc} /></DownloadImg>
            <DownloadImg target="_blank" href={ANDROID_WORKWISE_LINK}><img src={AndroidSrc} /></DownloadImg>
          </DownloadsContent>
        </DownloadsContainer>
      </Footer>

    </OnePageLayout>
  )
}

export default Workwise

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "workwise/Logo-Light.png" }) {
      childImageSharp {
        fluid(maxWidth: 306) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    logodark: file(relativePath: { eq: "workwise/Logo-Dark.png" }) {
      childImageSharp {
        fixed(width: 240) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    flowerbg: file(relativePath: { eq: "workwise/flower-bg.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    footer: file(relativePath: { eq: "workwise/footer.png" }) {
      childImageSharp {
        fixed(width: 130) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`
