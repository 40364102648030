import React from "react"
import styled from "styled-components"

import theme from "../../theme"

const CurvedText = styled.div`
  margin-bottom: ${({ $overlap, $r }) => ($overlap ? `-${$r}px` : "0")};
  width: ${({ $offset, $d }) => $d + $offset * 2}px;
  height: ${({ $offset, $r }) => $r + $offset}px;
  path {
    fill: transparent;
  }
  text {
    fill: currentColor;
    text-anchor: middle;
  }
`

const Text = styled.text`
  font-family: ${theme.typography.gascogne};
  font-size: 1.5em;
`

const CurveText = ({
  children,
  objectSize = 200,
  spacing = 0,
  offset = 30,
  overlap = false,
  className,
}) => {
  const d = objectSize + spacing * 2
  const r = objectSize / 2 + spacing / 2

  return (
    <CurvedText
      $offset={offset}
      $overlap={overlap}
      $r={r}
      $d={d}
      className={className}
    >
      <svg viewBox={`0 0 ${d + offset * 2} ${r + offset * 2}`}>
        <path
          id="curve"
          d={`M${offset},${r + offset} A${r},${r} 0 0,1 ${d + offset},${r +
            offset}`}
        />
        <Text width="500">
          <textPath xlinkHref="#curve" startOffset="50%">
            {children}
          </textPath>
        </Text>
      </svg>
    </CurvedText>
  )
}

export default CurveText
