import styled from "styled-components";
import { BREAKPOINT } from "../constant";


export const Mobile = styled.div`
  @media (min-width: ${BREAKPOINT.PHONE}px) {
    display: none;
  }
`

export const Desktop = styled.div`
  @media (max-width: ${BREAKPOINT.PHONE}px) {
    display: none;
  }
`