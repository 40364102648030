import React, { useEffect } from "react"
import styled, { createGlobalStyle } from "styled-components"

import theme from "../theme"
import { scrollTop } from "../helper"

const GlobalStyle = createGlobalStyle`
  .slick-track {
    display: flex;
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
    justify-content: center;
  }

  p {
    strong {
      font-size: inherit;
    }
  }
`

export const Container = styled.div`
  background-color: ${theme.color.workwise.background};
  color: ${theme.color.darkBlue};
`


const OnePageLayout = ({ children }) => {
  useEffect(() => {
    scrollTop()
  }, [])

  return (
    <>
      <GlobalStyle />
      <Container>{children}</Container>
    </>
  )
}

export default OnePageLayout
