import React from "react"
import Img from "gatsby-image"
import Slider from "react-slick"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import { Section, Col4, Grid, Odd } from "../Grid"
import { H2 } from "../Text"
import { Person, Center, FoundersGrid, SliderContainer, sliderSettings } from "./Founders"
import { EXPERTS } from "../../../constant"
import { Desktop, Mobile } from "../../Device"

const QUERY = graphql`
  query {
    expert1: file(relativePath: { eq: "workwise/experts/1.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    expert2: file(relativePath: { eq: "workwise/experts/2.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    expert3: file(relativePath: { eq: "workwise/experts/3.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    expert4: file(relativePath: { eq: "workwise/experts/4.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`


const Experts = () => {
  const data = useStaticQuery(QUERY)

  return (
    <Odd>
      <Section $paddingBottom>
        <H2>Les experts</H2>
        <Center>
          Entrepreneurs, philosophes, chercheurs, médecins, sportifs,<br />
          ils pratiquent la pleine conscience et nous en parlent via des témoignages inspirants,<br />
          à retrouver dans les 24 jours d’e-learning sur l’application dédiée.
        </Center>

        <Desktop>
          <FoundersGrid>
            {EXPERTS.map((expert, index) => (
              <Col4 key={index}>
                <Person>
                  <Img fluid={data[`expert${index + 1}`].childImageSharp.fluid} />
                  <h4>
                    {expert.name}<br /><span>{expert.lastname}</span>
                  </h4>
                  <p dangerouslySetInnerHTML={{ __html: expert.bio }} />
                </Person>
              </Col4>
            ))}
          </FoundersGrid>
        </Desktop>

        <Mobile>
          <SliderContainer>
            <Slider {...sliderSettings}>
              {EXPERTS.map((expert, index) => (
                <Person key={index}>
                  <Img fluid={data[`expert${index + 1}`].childImageSharp.fluid} />
                  <h4>{expert.name}<br /><span>{expert.lastname}</span></h4>
                  <p dangerouslySetInnerHTML={{ __html: expert.bio }} />
                </Person>
              ))}
            </Slider>
          </SliderContainer>
        </Mobile>
      </Section>
    </Odd>
  )
}

export default Experts
