import React from "react"
import styled from "styled-components"

import plant from "../../../assets/workwise/plant.png"
import start from "../../../assets/workwise/quote-start.png"
import end from "../../../assets/workwise/quote-end.png"
import theme from "../../../theme"
import { Col, Grid, Section } from "../Grid"
import { H2, H3 } from "../Text"
import { BREAKPOINT } from "../../../constant"

const Container = styled.div`
  background-image: url(${plant});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  color: ${theme.color.workwise.background};
  background-color: ${theme.color.darkBlue};
  padding-bottom: 150px;
`
const QuoteGrid = styled(Grid)`
  margin-top: 80px;
`
const Text = styled.p`
  font-family: ${theme.typography.circularBook};
  font-size: 20px;
  line-height: 1.5em;
  text-align: justify;

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    font-size: 16px;
    text-align: center;
  }
`
const DisclaimerContainer = styled.div`
  margin-top: 50px;
  position: relative;
  padding: 100px 0px 50px;

  &:before {
    content: "";
    position: absolute;
    width: 127px;
    height: 103px;
    background-image: url(${start});
    background-repeat: no-repeat;
    left: 0;
    bottom: 30%;

    @media (max-width: ${BREAKPOINT.PHONE}px) {
      top: 0;
      left: -10px;
      width: 60px;
      height: 50px;
      background-size: cover;
    }
  }

  &:after {
    content: "";
    position: absolute;
    width: 127px;
    height: 103px;
    background-image: url(${end});
    background-repeat: no-repeat;
    right: 0;
    bottom: 30%;

    @media (max-width: ${BREAKPOINT.PHONE}px) {
      right: -10px;
      bottom: -20px;
      width: 60px;
      height: 50px;
      background-size: cover;
    }
  }
`
const Disclaimer = styled.p`
  text-align: center;
  font-family: ${theme.typography.gascogne};
  font-size: 30px;
  line-height: 1.5em;
  margin: 0 auto;
  max-width: 680px;


  @media (max-width: ${BREAKPOINT.PHONE}px) {
    font-size: 22px;
    line-height: normal;
    text-align: center;
  }
`
const Author = styled.div`
  text-align: center;

  & > div {
    font-size: 18px;
  }

  & > div:nth-child(1) {
    font-family: ${theme.typography.gothic};
  }
  & > div:nth-child(2) {
    margin-top: 10px;
    font-family: ${theme.typography.gascogne};
  }
  & > div:nth-child(3) {
    font-size: 12px;
    letter-spacing: 2px;
    font-family: ${theme.typography.circular};
  }

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    margin-top: 50px;
  }
`

const Quote = () => {
  return (
    <Container>
      <Section>
        <H2>Nous grandissons<br />ensemble</H2>
        <H3>Construire un environnement<br />plus résilient</H3>
        <QuoteGrid>
          <Col>
            <Text>
              Ce programme s’adresse à tous, depuis les dirigeants jusqu’aux
              jeunes recrues, quel que soit leur métier et quel que soit le type
              d’organisation, grand groupe, startup, institution publique ou
              ONG.
            </Text>
          </Col>
          <Col>
            <Text>
              Toute personne ayant besoin de s’orienter, tisser des relations,
              prendre des décisions éclairées, créer et contribuer au projet de
              l’organisation en cultivant son équilibre et sa résilience.
            </Text>
          </Col>
        </QuoteGrid>
        <DisclaimerContainer>
          <Disclaimer>
            Une expérience unique, qui enrichit<br />
            l’expérience humaine des collaborateurs<br />
            et les aide dans leur contexte<br />
            professionnel au quotidien.<br />
            <br />
            À intégrer absolument dans le catalogue<br />
            de formation RH de l’entreprise
          </Disclaimer>
        </DisclaimerContainer>
        <Author>
          <div>ALICE D.</div>
          <div>Direction des <br />Ressources Humaines</div>
          <div>AIR FRANCE</div>
        </Author>
      </Section>
    </Container>
  )
}

export default Quote
