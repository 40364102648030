import styled from "styled-components"
import theme from "../../theme"
import { BREAKPOINT } from "../../constant"

export const Section = styled.section`
  max-width: ${theme.grid.fullWidth}px;
  margin: 0 auto;
  padding: 160px 80px 0;
  ${({ $paddingBottom }) => ($paddingBottom ? `padding-bottom: 130px;` : ``)}

  @media (max-width: ${BREAKPOINT.PHONE}px) {    
    padding: 120px 25px 0;
    ${({ $paddingBottom }) => ($paddingBottom ? `padding-bottom: 130px;` : ``)}
  }
`

export const Odd = styled.div`
  color: ${theme.color.workwise.background};
  background-color: ${theme.color.darkBlue};
`

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -25px;
  margin-right: -25px;
`
export const Col = styled.div`
  flex: 1;
  padding: 0 25px;
  position: relative;
  ${({ $center }) => ($center ? `text-align: center;` : ``)}
  ${({ $minWidth }) => `min-width: ${$minWidth || 300}px;`}

  @media (max-width: ${BREAKPOINT.PHONE}px) {
    margin-top: 30px;
  }
`

export const Col4 = styled.div`
  flex: 1 0 21%;
  min-width: 250px;
`
